exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy_policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-templates-blogpost-index-jsx": () => import("./../../../src/templates/blogpost/index.jsx" /* webpackChunkName: "component---src-templates-blogpost-index-jsx" */),
  "component---src-templates-homepage-index-jsx": () => import("./../../../src/templates/homepage/index.jsx" /* webpackChunkName: "component---src-templates-homepage-index-jsx" */),
  "component---src-templates-tagpage-index-jsx": () => import("./../../../src/templates/tagpage/index.jsx" /* webpackChunkName: "component---src-templates-tagpage-index-jsx" */)
}

